import { MetaTaggerDirective } from './../../utility/directives/meta-tagger.directive';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DateAdapter, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { LocalizedNumberPipe } from 'src/app/utility/pipes/number-localized.pipe';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutofocusDirective } from 'src/app/utility/directives/autofocus.directive';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { LocalizedDatePipe } from 'src/app/utility/pipes/date-localized.pipe';
import { PurchaseOrderItemFilter } from "src/app/utility/pipes/purchase-order-item-filter.pipe";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { PicturesPipe } from 'src/app/utility/pipes/pictures.pipe';
import { TagsBusquedaPipe } from 'src/app/utility/pipes/tagsBusquedas.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSliderModule } from '@angular/material/slider';
import { NumberOnlyDirective } from 'src/app/utility/directives/numbersOnly.directive';
import { SafePipe } from 'src/app/utility/pipes/safe.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { AvatarModule } from 'ngx-avatar';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { CustomDateAdapter } from 'src/app/utility/adapters/custom-date-adapter';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { SafeHTMLPipe } from 'src/app/utility/pipes/safeHTML.pipe';

@NgModule({
  declarations: [
    LocalizedNumberPipe,
    AutofocusDirective,
    NumberOnlyDirective,
    LocalizedDatePipe,
    PurchaseOrderItemFilter,
    SafePipe,
    TagsBusquedaPipe,
    PicturesPipe,
    MetaTaggerDirective,
    SafeHTMLPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CarouselModule,
    SlickCarouselModule,
    MatIconModule,
    MatMenuModule,
    OverlayModule,
    MatToolbarModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatSidenavModule,
    MatRippleModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    LocalizedNumberPipe,
    TagsBusquedaPipe,
    LocalizedDatePipe,
    PurchaseOrderItemFilter,
    SafePipe,
    MatProgressBarModule,
    InViewportModule,
    MatRadioModule,
    MatSnackBarModule,
    CreditCardDirectivesModule,
    MatTooltipModule,
    AutofocusDirective,
    NumberOnlyDirective,
    MatChipsModule,
    MatListModule,
    MatTableModule,
    MatCheckboxModule,
    MatTabsModule,
    MatCardModule,
    PicturesPipe,
    MatExpansionModule,
    NgxPaginationModule,
    MatSliderModule,
    LazyLoadImageModule,
    ContentLoaderModule,
    MatDialogModule,
    MatSlideToggleModule,
    NgxsFormPluginModule,
    AvatarModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    MatPaginatorModule,
    MetaTaggerDirective,
    SafeHTMLPipe,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-Ar' },
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class ThirdLibModule { }
