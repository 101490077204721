export namespace CartActions {
  export class Update {
    static readonly type = '[Cart] Update Data';
    constructor(public values: any) { }
  }
  export class OpenMenu {
    static readonly type = '[Cart] Open Menu';
    constructor() { }
  }
  export class CloseMenu {
    static readonly type = '[Cart] Close Menu';
    constructor() { }
  }
  export class SetCarritoCustomer {
    static readonly type = '[Cart] Set Carrito Customer';
    constructor(public idCarrito: number) { }
  }
  export class RetrieveCarrito {
    static readonly type = '[Cart] Retrieve Carrito';
    constructor(public idCarrito: number) { }
  }
  export class CheckCarrito {
    static readonly type = '[Cart] Check Carrito';
    constructor(public idCarrito: number) { }
  }
  export class LoadPagos {
    static readonly type = '[Cart] Loag Formas Pago Privado';
    constructor(public ordenes: string) { }
  }
  export class AddItemCarrito {
    static readonly type = '[Cart] Add Item Carrito';
    constructor(public producto: any, public cantidad: number) { }
  }
  export class AddItemCarritoExistente {
    static readonly type = '[Cart] Add Item Carrito Existente';
    constructor(
      public idCarrito: number,
      public producto: any,
      public cantidad: number
    ) { }
  }
  export class RemoveItemCarrito {
    static readonly type = '[Cart] Remove Item Carrito';
    constructor(public idProducto: number) { }
  }
  export class ChangeAmountProducto {
    static readonly type = '[Cart] Change Amount Producto';
    constructor(public idItemCarrito: number, public cantidad: number) { }
  }
  export class AddVoucher {
    static readonly type = '[Cart] Add Voucher';
    constructor(public voucher: string) { }
  }
  export class ClearCarritoData {
    static readonly type = '[Cart] Clear Carrito Data';
    constructor() { }
  }
  export class ClearCarritoCliente {
    static readonly type = '[Cart] Clear Carrito Cliente';
    constructor(public idCarrito: number) { }
  }
}
