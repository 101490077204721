export const atiendaConfiguration = {
    site_name: 'Atienda',
    theme_color: '#3EDBF5',
    theme_accent_color: '#666666',
    title: 'Atienda',
    Abstract: 'Atienda | Comprá Online, Viví en Línea.',
    author: 'Atienda',
    copyright: 'Atienda',
    site_down_favicon: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS66XNFqTg2yhQfbLqHKACEUQmPCiEjtrrk4g&usqp=CAU',
    site_down_title: 'Atienda',
    client_att_email: 'hola@atienda.com.ar',
    optional_prescription: false,
    shipping_services_active: true,
    canonical_host: 'null',
}