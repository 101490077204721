import { PublicoActions } from 'src/app/store/publico/publico.actions';
import { GtmService } from 'src/app/tracking/gtm.service';
import { PublicoState } from 'src/app/store/publico/publico.state';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Actions, Select, Store, ofActionSuccessful, ofActionErrored } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { IconService } from './services/utility/icon.service';
import { OverlayErrorService } from './services/utility/overlay/overlayError.service';
import { OverlayLoadingService } from './services/utility/overlay/overlayLoading.service';
import { ThemingService } from './services/utility/theming.service';
import { UpdateSessionService } from './services/utility/updateSession.service';
import { UpdateService } from './services/utility/updateVersion.service';
import { CuentasActions } from './store/cuentas/cuentas.actions';
import { GlobalActions } from './store/global/global.actions';
import { GlobalState } from './store/global/global.state';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  themingSubscription: Subscription;
  @Select(PublicoState.maintenance)
  maintenance$: Observable<any>;
  @Select(GlobalState.isLoading)
  loader$: Observable<boolean>;
  @Select((state) => state.global.showError)
  error$: Observable<boolean>;
  primaryThemeColor: string = environment.configuration.theme_color;
  accentThemeColor: string = environment.configuration.theme_accent_color;
  favIcon: any = document.querySelector('#faviconCliente');
  pageFavicon: string;
  pageTitle: string;
  constructor(
    private themingService: ThemingService,
    private router: Router,
    private renderer: Renderer2,
    private sw: UpdateService,
    private iconService: IconService,
    private overlayLoadingService: OverlayLoadingService,
    private overlayErrorService: OverlayErrorService,
    private sessionService: UpdateSessionService,
    private store: Store,
    private gtmService: GtmService,
    private action$: Actions,
    private titleService: Title,
    private route: ActivatedRoute
  ) { 
    store.dispatch( new GlobalActions.SetThemeColors(this.primaryThemeColor,this.accentThemeColor))
  }
  @HostBinding('class') public cssClass: string;
  ngOnInit() {
    this.maintenance$.subscribe((mantenimiento) => {
      if (mantenimiento) {
        this.router.navigate(['/mantenimiento']);
      }
    });
    /**Trackeo de cambio de url para GtagManager */
    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        this.gtmService.pageView(item.url);
      }
    });
    this.action$
      .pipe(ofActionSuccessful(PublicoActions.LoadInfo))
      .subscribe(() => {
        this.primaryThemeColor = this.store.selectSnapshot(
          (state: any) => state.publico.info.style?.color.primary)
        this.accentThemeColor = this.store.selectSnapshot(
          (state: any) => state.publico.info.style?.color.accent)
        this.pageFavicon = this.store.selectSnapshot(
          (state: {
            publico: { info: { general: { site_favicon: string } } };
          }) => state.publico.info.general.site_favicon
        );
        this.pageTitle = this.store.selectSnapshot(
          (state: {
            publico: { info: { general: { site_title: string } } };
          }) => state.publico.info.general.site_title
        );
        this.favIcon.href = this.pageFavicon;
        this.store.dispatch([
          new CuentasActions.CheckToken(),
          new GlobalActions.SetThemeColors(
            this.primaryThemeColor,
            this.accentThemeColor
          ),
        ]);
      });
    this.action$
    .pipe(ofActionErrored(PublicoActions.LoadInfo))
    .subscribe(() => {
      this.favIcon.href = environment.configuration.site_down_favicon;
      this.titleService.setTitle(environment.configuration.site_down_title);
    })
    this.sessionService.start();
    this.sw.checkForUpdates();
    this.iconService.registerFontAwsome();
    this.themingSubscription = this.themingService.theme.subscribe(
      (theme: string) => {
        this.renderer.removeClass(document.body, this.cssClass);
        this.cssClass = theme;
        this.renderer.addClass(document.body, this.cssClass);
      }
    );
    this.loader$.subscribe((bool) => {
      if (bool) {
        this.overlayLoadingService.open();
      } else {
        this.overlayLoadingService.close();
      }
    });
    this.error$.subscribe((bool) => {
      if (bool) {
        const errores = this.store.selectSnapshot((res) => res.global.errores);
        this.overlayErrorService.open(errores);
      } else {
        this.overlayErrorService.close();
      }
    });
  }
  ngOnDestroy() {
    this.themingSubscription.unsubscribe();
  }
}
