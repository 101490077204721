import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { ErroresService } from 'src/app/services/extras/errores.service';
import {
  DatosError,
  Error,
} from 'src/app/services/utility/overlay/overlayLoaderConfig';
import { ThemingService } from 'src/app/services/utility/theming.service';
import { GlobalActions } from './global.actions';
export class PortalTheme {
  public icono: any;
  public colorPrimario: string;
  public colorSecundario: string;
  public tituloPortal: string;
}
export class GlobalStateModel {
  public scroll: number;
  public showFloatMenu: boolean;
  public showLoading: boolean[];
  public showError?: boolean;
  public errores?: DatosError;
  public decision?: Error;
  public portal?: PortalTheme;
}
const defaults = {
  scroll: 0,
  showFloatMenu: true,
  showLoading: [],
  showError: false,
  errores: null,
  decision: null,
};
@State<GlobalStateModel>({
  name: 'global',
  defaults,
})
@Injectable()
export class GlobalState {
  @Selector()
  static isLoading(state: GlobalStateModel) {
    const isLoading = state.showLoading.find((elem) => elem);
    if (isLoading) {
      return true;
    } else {
      return false;
    }
  }
  constructor(
    private themingService: ThemingService,
    private errorService: ErroresService
  ) {}
  @Action(GlobalActions.UpdateScroll)
  updScroll(
    { getState, setState }: StateContext<GlobalStateModel>,
    { scroll }: GlobalActions.UpdateScroll
  ) {
    const state = getState();
    setState(patch({ scroll }));
  }
  @Action(GlobalActions.HideFloatMenu)
  hide({ getState, setState }: StateContext<GlobalStateModel>) {
    const state = getState();
    setState(patch({ showFloatMenu: false }));
  }
  @Action(GlobalActions.ShowFloatMenu)
  show({ getState, setState }: StateContext<GlobalStateModel>) {
    const state = getState();
    setState(patch({ showFloatMenu: true }));
  }
  @Action(GlobalActions.ShowLoader,{cancelUncompleted: true})
  showLoader({ getState, setState }: StateContext<GlobalStateModel>) {
    const state = getState();
    setState(patch({ showLoading: [...state.showLoading, true] }));
  }
  @Action(GlobalActions.HideLoader)
  hideLoader({ getState, setState }: StateContext<GlobalStateModel>) {
    const state = getState();
    const newShowLoading = [...state.showLoading].splice(1);
    setState(patch({ showLoading: newShowLoading }));
  }
  @Action(GlobalActions.ShowError)
  ShowError(
    { getState, setState }: StateContext<GlobalStateModel>,
    { errores }: GlobalActions.ShowError
  ) {
    const state = getState();
    setState(patch({ showError: true, errores }));
  }
  @Action(GlobalActions.HideError)
  HideError(
    { getState, setState }: StateContext<GlobalStateModel>,
    { decision }: GlobalActions.HideError
  ) {
    const state = getState();
    setState(patch({ showError: false, decision }));
  }
  @Action(GlobalActions.SetThemeColors)
  SetThemeColors({}: StateContext<GlobalStateModel>,
    { primary,accent }) {
    this.themingService.savePrimaryColor(primary);
    this.themingService.saveAccentColor(accent);
  }
  @Action(GlobalActions.SendErrors)
  SendErrors(
    {}: StateContext<GlobalStateModel>,
    { error }: GlobalActions.SendErrors
  ) {
    return this.errorService.sendError(error).pipe(
      tap(
        (farmArray) => {},
        (err) => {}
      )
    );
  }
}
