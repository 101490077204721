import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as urls from '../utility/urls';

@Injectable({
  providedIn: 'root',
})
export class FarmaciasService {
  constructor(private http: HttpClient) { }
  getFarmaciasPublico(search: string = '', location: string = ''): Observable<any> {
    return this.http
      .get<any>(environment.api + urls.getFarmaciasPublico, {
        params: {
          search,
          location
        }
      })
  }
  getFarmaciasLocales(idFarmacia): Observable<any> {
    return this.http.get<any>(
      environment.api + urls.farmacias + idFarmacia + '/' + urls.locales
    );
  }
  getFarmaciasStaff(idFarmacia): Observable<any> {
    return this.http.get<any>(
      environment.api + urls.farmacias + idFarmacia + '/' + urls.staff
    );
  }
  updateLocal(idLocal, data): Observable<any> {
    return this.http.put<any>(
      environment.api + urls.farmacias + urls.locales + idLocal,
      data
    );
  }
  addLocal(idFarmacia, data): Observable<any> {
    return this.http.post<any>(
      environment.api + urls.farmacias + idFarmacia + '/' + urls.locales,
      data
    );
  }
  getData(): Observable<any> {
    return this.http.get<any>(environment.api + urls.getData);
  }
  updateData(idFarmacia, fantasia, nombre, cuit, telefono): Observable<any> {
    return this.http.put<any>(environment.api + urls.farmacias + idFarmacia, {
      fantacy_name: fantasia,
      business_name: nombre,
      CUIT: cuit,
      phone: telefono,
    });
  }
  updateLogo(idFarmacia, data): Observable<any> {
    return this.http.put<any>(
      environment.api + urls.farmacias + idFarmacia,
      data
    );
  }
  registroFarmacia(datos): Observable<any> {
    return this.http.post<any>(environment.api + urls.farmacias, datos);
  }
  addFavorito(idFarmacia): Observable<any> {
    return this.http.post<any>(environment.api + urls.farmaciasAddFavorito, {
      pharmacy_locations: [idFarmacia],
    });
  }
  deleteFavorito(idFarmacia): Observable<any> {
    return this.http.delete<any>(
      environment.api + urls.farmaciasDeleteFavorito + idFarmacia
    );
  }
  getStatsFarmacia(date: string): Observable<any> {
    return this.http.post<any>(
      environment.api + urls.getStats,
      {
        date: date
      }
    )
  }
  getFarmaciaAccessToken(): Observable<any> {
    return this.http.get<any>(
      environment.api + urls.farmaciasAccessToken
    )
  }
  getLocationsFarmacias(search: string = ""): Observable<any> {
    return this.http.get<any>(
      environment.api + urls.farmacias + urls.locales + urls.cities, {
      params: {
        search
      }
    }
    )
  }
  getShippingServices(cartId: number, pharmacyLocationId: number, userAddressId: number): Observable<any> {
    return this.http.post(environment.api + urls.shippingServices + urls.checkShippingServices, {
      'shopping_cart_id': cartId,
      'pharmacy_location_id': pharmacyLocationId,
      'address_id': userAddressId,
    })
  }
  calculateShippingCost(addresId: number, pharmacyLocationId: number): Observable<any> {
    return this.http.post(environment.api + urls.shippings + urls.calculateCost , {
      'address_id': addresId,
      'pharmacy_location_id': pharmacyLocationId
    })
  }
}