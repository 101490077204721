import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RolesHabilitados } from '../utility/publico-headers';
import * as urls from '../utility/urls';
import { getHeaders } from '../utility/publico-headers';
import { map } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class GestionUsuariosService {
  constructor(private http: HttpClient) { }

  getRoles(usuario): Observable<any> {
    return this.http
      .post<any>(environment.api + urls.getRoles, {
        email: usuario,
      })
      .pipe(
        map((res) => {
          const items = res.roles.filter((rol) =>
            RolesHabilitados.includes(rol.name)
          );
          return { ...res, roles: items };
        })
      );
  }
  getRecoveryCode(usuario): Observable<any> {
    return this.http.post<any>(environment.api + urls.passRecovery, {
      email: usuario,
    });
  }
  checkRecoveryCode(usuario, recovery): Observable<any> {
    return this.http.post<any>(environment.api + urls.checkRecovery, {
      email: usuario,
      code: recovery,
    });
  }
  changePassword(usuario, recovery, password): Observable<any> {
    return this.http.post<any>(environment.api + urls.updatePass, {
      email: usuario,
      code: recovery,
      password,
    });
  }
  update(data, rol): Observable<any> {
    const headersRol = getHeaders(rol);
    const datos = { ...data };
    delete datos.email;
    return this.http.put<any>(environment.api + urls.updateCustomer, datos, {
      headers: headersRol,
    });
  }
  login(usuario, password, rol): Observable<any> {
    const headersRol = getHeaders(rol);
    return this.http.post<any>(
      environment.api + urls.getToken,
      {
        email: usuario,
        password,
      },
      { headers: headersRol }
    );
  }
  registro(data): Observable<any> {
    return this.http.post<any>(environment.api + urls.registro, data);
  }
  getDatos(): Observable<any> {
    return this.http.get<any>(environment.api + urls.getCustomer);
  }
  refreshToken(refresh, rol) {
    const headerRefresh = { ...getHeaders(rol), Authorization: refresh };
    return this.http.post<any>(
      environment.api + urls.getRefresh,
      {},
      {
        headers: headerRefresh,
      }
    );
  }
  loginSocial(data, rol): Observable<any> {
    const headerSocial = {
      ...getHeaders(rol),
      'Authorization-Type': data.provider,
    };
    return this.http.post<any>(environment.api + urls.getToken, data, {
      headers: headerSocial,
    });
  }
  getInsuranceNames(): Observable<any> {
    return this.http.get<any>(environment.api + urls.getInsuranceNames);
  }
  addInsurance(formData: any): Observable<any> {
    return this.http.post<any>(environment.api + urls.customers + urls.addInsurance, {
      "medical_insurance_id": formData.medical_insurance_id,
      "plan": formData.plan,
      "number": formData.number,
      "credential_picture": formData.credential_picture,
    });
  }
  editInsurance(insuranceId: number, formData: any): Observable<any> {
    let requestBody: any = {
      "medical_insurance_id": formData.medical_insurance_id,
      "plan": formData.plan,
      "number": formData.number,
    };
    if(formData.credential_picture != null) {
      requestBody = {
        "medical_insurance_id": formData.medical_insurance_id,
        "plan": formData.plan,
        "number": formData.number,
        "credential_picture": formData.credential_picture,
      }
    }
    return this.http.put<any>(environment.api + urls.customers + urls.editInsurance + insuranceId, requestBody);
  }
  deleteInsurance(insuranceId: number): Observable<any> {
    return this.http.delete(environment.api + urls.customers + urls.deleteInsurance + insuranceId);
  }
}
