import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class HelperFunctions {
  constructor(private translocoService: TranslocoService) { }
  getLang(): any {
    let lang = 'en_US';
    const activeLang = this.translocoService.getActiveLang();
    switch (activeLang) {
      case 'es':
        lang = 'es_AR';
        break;
      case 'us':
        lang = 'en_US';
        break;
    }
    return lang;
  }
  encodeString(originString: string): string {
    let encodedString: string = originString
      .replace('@', '%40')
      .replace(' ', '%20')
      .replace(':', '%3A')
      .replace('$', '%24')
      .replace(',', '%2C')
      .replace('-', '%2D')
      .replace(';', '%3B')
      .replace('+', '%2B')
      .replace('=', '%3D')
      .replace('?', '%3F')
      .replace('/', '%2F');
    return encodedString;
  }
  decodeString(originString: string): string {
    let decodedString: string = originString
      .replace(/%40/gi, '@')
      .replace(/%20/gi, ' ')
      .replace(/%3A/gi, ':')
      .replace(/%24/gi, '$')
      .replace(/%2C/gi, ',')
      .replace(/%2D/gi, '-')
      .replace(/%3B/gi, ';')
      .replace(/%2B/gi, '+')
      .replace(/%3D/gi, '=')
      .replace(/%3F/gi, '?')
      .replace(/%2F/gi, '/');
    return decodedString;
  }
}
