import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { tap } from 'rxjs';
import { MenuService } from 'src/app/services/extras/menus.service';
import { DevolucionesService } from 'src/app/services/usuarios/devoluciones.service';
import { PublicoActions } from './publico.actions';
import * as compareObjects from 'src/app/utility/helpers/compare-stringified';
export class Menu {
  public id: number;
  public name: string;
  public url: string;
  public type: string;
}
export interface Message {
  message: string;
  ['url-action']: string;
}

export interface Header {
  logo: string;
  messages: Message[];
  page_title?: string;
}

export interface Contact {
  phone: string;
  whatsapp: string;
  email: string;
  facebook: string;
  instagram: string;
  twitter: string;
  webpage: string;
}

export interface Footer {
  contact: Contact;
  info: FooterInfo[];
  logos_url: string[];
}

export interface FooterInfo {
  title: string;
  links: FooterLinks[];
}

export interface FooterLinks {
  text: string;
  url: string;
}

export interface Maps {
  market: Market;
}

export interface Market {
  base: string;
  selected: string;
}
export interface General {
  oauth: boolean;
  active_benefit_points?: boolean;
  active_pharmacy?: boolean;
  credentials?: any;
  mix_payment_mode?: boolean;
  maintenance?: boolean;
  site_favicon: string;
  site_title: string;
  externalClient?: boolean;
}
export interface Loader {
  logo: string;
  background: LoaderBackground;
  title: string;
}

export interface LoaderBackground {
  color: string;
  image: string;
}
export interface Style {
  color: Color;
}
export interface Color {
  primary: string;
  accent: string;
}
export class Info {
  header: Header;
  footer: Footer;
  general: General;
  ĺoader: Loader;
  style: Style;
}
export class PublicoStateModel {
  public menus: Menu[];
  public sidenav: boolean;
  public render: boolean;
  public info: Info;
  public page: string;
  public newDevolucionForm: any;
  public hidePrescriptionBtn: boolean;
}

const defaults = {
  page: '',
  sidenav: false,
  render: true,
  scroll: 0,
  menus: [],
  info: {
    header: null,
    footer: null,
    general: null,
    ĺoader: null,
    style: {
      color: {
        primary: '#333333',
        accent: '#999999',
      }
    },
  },
  newDevolucionForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: { required: '' },
  },
  hidePrescriptionBtn: false,
};

@State<PublicoStateModel>({
  name: 'publico',
  defaults,
})
@Injectable()
export class PublicoState {
  @Selector()
  static getPrimaryColor(state: PublicoStateModel) {
    return state.info.style?.color.primary || '#333333';
  }
  @Selector()
  static getAccentColor(state: PublicoStateModel) {
    return state.info.style?.color.accent || '#999999';
  }
  @Selector()
  static maintenance(state: PublicoStateModel) {
    return state.info.general.maintenance;
  }
  @Selector()
  static whatsapp(state: PublicoStateModel) {
    return state.info.footer.contact.whatsapp;
  }
  @Selector()
  static getMessages(state: PublicoStateModel) {
    return state.info.header.messages;
  }
  @Selector()
  static getHeader(state: PublicoStateModel) {
    return state.info.header;
  }
  constructor(
    private menuService: MenuService,
    private devolucionesService: DevolucionesService
  ) { }
  @Action(PublicoActions.CloseSide)
  close({ getState, setState }: StateContext<PublicoStateModel>) {
    const state = getState();
    setState(patch({ sidenav: false, render: !state.render }));
  }
  @Action(PublicoActions.OpenSide)
  open({ getState, setState }: StateContext<PublicoStateModel>) {
    const state = getState();
    setState(patch({ sidenav: true, render: !state.render }));
  }
  @Action(PublicoActions.UpdateState)
  update(
    { setState }: StateContext<PublicoStateModel>,
    { values }: PublicoActions.UpdateState
  ) {
    setState(patch({ ...values }));
  }
  @Action(PublicoActions.LoadMenus)
  add({ setState }: StateContext<PublicoStateModel>) {
    return this.menuService.getMenus().pipe(
      tap((menus) => {
        setState(
          patch({
            menus: menus.menus,
          })
        );
      })
    );
  }
  @Action(PublicoActions.LoadInfo)
  LoadInfo({ setState }: StateContext<PublicoStateModel>) {
    return this.menuService.getInfo().pipe(
      tap((info) => {
        if(!compareObjects.compareStringified(localStorage.getItem('publico.info'),JSON.stringify(info?.data))) {
          setState(
            patch({
              info: info.data,
            })
          );
        }
      })
    );
  }
  @Action(PublicoActions.CreateDevolucion)
  CreateDevolucion({ getState }: StateContext<PublicoStateModel>) {
    const state = getState();
    return this.devolucionesService
      .createDevolucion(state.newDevolucionForm.model)
      .pipe(
        tap(
          (result) => { },
          (err) => {
            throw new Error('');
          }
        )
      );
  }
  @Action(PublicoActions.UrlBlackList)
  urlBlackList({ getState }: StateContext<PublicoStateModel>) {

  }
  @Action(PublicoActions.HidePrescription)
  hidePrescription({ setState }: StateContext<PublicoStateModel>,
    { hideBtn }: PublicoActions.HidePrescription) {
    setState(
      patch({
        hidePrescriptionBtn: hideBtn
      })
    )
  }
}
