export const publicated = 'publicated/'
export const publicacionProductos = `${publicated}retrieve-one/`;
export const getPublicaciones = `${publicacionProductos}retrieve-many/`;
export const getAllPublications = `${publicated}retrieve-many/`;

export const token = 'token/';
export const getRoles = `${token}getRoles/`;

export const orders = 'purchase-orders/'
export const createOrder = `${orders}prepare/`;
export const getOrder = `${orders}`;
export const getCompras = `${orders}my-list/`;
export const getVentas = `${orders}pharmacy-location-list/`;
export const getEstadosFiltros = `${orders}filter-statuses/`;
export const updateState = `update-payments`
export const reporteVentas = `${orders}report`;
export const reportesIndex = 'reports/';
export const downloadPreviousReport = `${reportesIndex}/download/`;

export const home = 'home-contents/';
export const getToken = `${token}get/`;
export const getRefresh = `${token}refresh`;

export const users = 'users/'
export const registro = `${users}register-costumer`;
export const passRecovery = `${users}password-recovery`;
export const checkRecovery = `${users}password-recovery-code`;
export const updatePass = `${users}modify-password`;

export const pages = 'pages/';
export const pageInfo = `front-configurations/data`;

export const imagenes = 'pictures/';
export const busquedas = 'search/index/';
export const busquedaAutoComplete = `search/matches/`;

export const categorias = 'categories/';
export const carrito = 'shopping-carts/';
export const addCupon = `${carrito}add-voucher/`;
export const removeCupon = `${carrito}rm-voucher/`;
export const getCarrito = `${carrito}owned-by/me/`;
export const mergeCarrito = `${carrito}merge`;
export const updateCarrito = `${carrito}update`;
export const createCarrito = `${carrito}new/`;
export const clearCarrito = 'clear';
export const menus = 'menus/'
export const pagos = 'payments/'
export const addresses = 'addresses/'
export const setAddress = 'select/';
export const getPagos = `payment-services/`;
export const getPagosPrivado = `${getPagos}check-allowed/`;
export const customers = 'customers/'
export const getCustomer = `${customers}get-my-data`;
export const getFavoritos = `${customers}get-my-wishes`;
export const addFavoritos = `${customers}add-to-wishes`;
export const deleteFavorito = `${customers}delete-wish`;
export const updateCustomer = `${customers}update-my-data`;
export const avisoSinStock = `${customers}stock-notifications`;


export const getPuntos = `${customers}get-my-benefit-points/`;
export const getCheckout = `${pagos}complex-checkout/`;
export const farmacias = `pharmacies/`;
export const getData = `${farmacias}data`;
export const getStats = `${farmacias}stats`
export const locales = `locations/`;
export const cities = `cities`;
export const staff = `staff/`;
export const getFarmaciasPublico = `${farmacias}locations`;
export const getFarmacias = `${farmacias}locations/customers-fav`;
export const farmaciasAddFavorito = `${customers}add-to-pharmacies/`;
export const farmaciasDeleteFavorito = `${customers}delete-pharmacies/`;
export const farmaciasAccessToken = `${token}access`


export const createDevolucion = `regrets/`;

export const subscribirse = 'newsletter-subcriptions/';
export const deleteSubscribirse = `${subscribirse}unsubscribe`

export const sendError = `errors/`;


/**Url para redireccion adminer con access token */
export const adminerAccess = `security/token-login`
/**Urls New Carrito 08/2022 */
export const addItem = `add-item`;
export const setCustomer = `set-customer`;
export const addItemCarrito = `${carrito}add-item`;
export const removeItemCarrito = `${carrito}rm-item/`;
export const existsCarrito = `${carrito}exists/`;
export const changeAmountProducto = `${carrito}set-item/`;
export const addVoucherCarrito = `add-voucher`;

/**Urls Manejo de Media 09/2022 */
export const attachPrescriptions = `attach-prescriptions` 

/**Urls Status de checkout 11/2022 */
export const getCheckoutStatus = `checkout-status`

/**Urls cuotas pago tarjeta 07/2023 */
export const installments = `installments/`;
export const getInstallments = `${installments}list/`;

/**Urls cobertura salud 11/2023 */
export const getInsuranceNames = `medical-insurances`;
export const addInsurance = `add-medical-insurance`;
export const editInsurance = `edit-medical-insurance/`;
export const deleteInsurance = `delete-medical-insurance/`;
/**Url popups 11/2023 */
export const sendPopupData = `data-pop-ups/add`;
/**Urls envío a domicilio 12/2023 */
export const shippings = `shippings/`;
export const shippingServices = `shipping-services/`;
export const calculateCost = `calculate`;
export const checkShippingServices = `check-allowed`;